
import DisplayStory from "@evercam/shared/components/DisplayStory"
import { useCameraStore } from "@/stores/camera"

export default {
  components: {
    DisplayStory,
  },
  data() {
    return {
      pageName: "",
    }
  },
  head() {
    return {
      title: `${useCameraStore().selectedCamera?.name || "Evercam"} | ${
        this.pageName
      }`,
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    storySlug() {
      return `info-pages/${this.$route.params.page}`
    },
  },
  methods: {
    updateStory({ name }) {
      this.pageName = name
    },
  },
}
