
import { useStoryblokBridge } from "@storyblok/nuxt-2"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  props: {
    storySlug: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      story: null,
    }
  },
  watch: {
    storySlug: {
      handler() {
        this.fetchStoryData()
      },
      immediate: true,
    },
  },
  methods: {
    async fetchStoryData() {
      this.story = null
      const { story } = await EvercamApi.storyblock.getStory({
        route: `/cdn/stories/${this.storySlug}`,
        params: "version=draft&cv=undefined",
      })
      this.story = story
      if (!this.story) {
        return
      }
      this.$emit("updated-story", this.story)
      useStoryblokBridge(this.story.id, (newStory) => (this.story = newStory))
    },
  },
}
